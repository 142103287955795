import React from "react"
import Modal from "components/Modal/Modal"
import { YotpoProductReviewsImagesData } from "typings/graphql"
import { ReviewImageSlider, ReviewImage } from "./ReviewGalleryModal.styles"

type ReviewGalleryProps = {
  activeImages: YotpoProductReviewsImagesData[]
  index: number
  setActiveImages: (
    value: React.SetStateAction<YotpoProductReviewsImagesData[]>
  ) => void
}

export const ReviewGalleryModal = ({
  activeImages,
  index,
  setActiveImages,
}: ReviewGalleryProps) => {
  return (
    <Modal galleryMode onModalClose={() => setActiveImages([])}>
      {activeImages.length === 1 ? (
        <ReviewImage
          src={activeImages[index].originalUrl}
          alt="Single Review Image"
        />
      ) : (
        <ReviewImageSlider
          dots
          arrows
          infinite
          initialSlide={index}
          lazyLoad="ondemand"
          speed={0}
          slidesToShow={1}
          slidesToScroll={1}
        >
          {activeImages.map((image, imgIndex) => (
            <ReviewImage
              key={imgIndex}
              src={image.originalUrl}
              alt={`Review Image ${imgIndex}`}
            />
          ))}
        </ReviewImageSlider>
      )}
    </Modal>
  )
}
