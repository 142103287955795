import styled from "styled-components"
import { mq, typography } from "styles"

export const ItemGrid = styled.div`
  padding: 24px 0;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 16px;
  grid-template-columns: 48px 1fr;
  grid-template-areas:
    "avatar meta"
    "body body";
  ${mq.minWidth("md")} {
    grid-template-areas:
      "avatar meta"
      ". body";
  }
`

export const InitialContainer = styled.div`
  margin-bottom: 16px;
  ${mq.minWidth("md")} {
    margin-bottom: 0;
  }
  grid-area: avatar;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: var(--reviews-avatar-fill);
  color: var(--reviews-avatar-text);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${typography.h5};
  line-height: 0.8;
`

export const InitialCheckmark = styled.div<{ $borderColor: string }>`
  border: 2px solid;
  border-color: ${({ $borderColor }) => $borderColor};
  position: absolute;
  bottom: -1px;
  right: -1px;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: var(--reviews-verified-fill);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--reviews-verified-icon);
  svg {
    width: 10px;
  }
`

export const ReviewMeta = styled.div`
  grid-area: meta;
`

export const ReviewBody = styled.div`
  grid-area: body;
`

export const ReviewerDateLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 24px;
  ${mq.minWidth("md")} {
    margin-bottom: 4px;
  }
`

export const Reviewer = styled.span`
  ${typography.bodySmall};
  color: ${({ theme }) => theme.localTheme.text?.primary.opacityHex};
`

export const VerifiedBuyer = styled.span`
  margin-left: 12px;
  ${typography.caption};
  color: var(--reviews-verified-text);
  font-size: 12px;
`

export const StarContainer = styled.div`
  ${mq.minWidth("md")} {
    margin-bottom: 16px;
  }
`

export const ReviewTitle = styled.div`
  ${typography.h5};
  color: var(--reviews-title-text);
  margin-top: 0px;
  margin-bottom: 4px;
`

export const ReviewContent = styled.div`
  ${typography.body};
  color: ${({ theme }) => theme.localTheme.text?.primary.opacityHex};
`

export const ReviewThumbContainer = styled.div`
  padding-top: 24px;
  row-gap: 8px;
`

export const ReviewThumb = styled.img`
  cursor: pointer;
  width: 120px;
  height: 120px;
  ${mq.maxWidth("md")} {
    width: 68px;
    height: 68px;
  }
  margin-right: 8px;
`
