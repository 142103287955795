import React from "react"
import PrevSvg from "assets/svg/caret-left.svg"
import NextSvg from "assets/svg/caret-right.svg"
import { generatePages } from "utils/reviewUtils"
import {
  Container,
  Pages,
  PageEllipsis,
  PageNumber,
  Next,
  Prev,
} from "./Pagination.styles"

type Props = {
  totalPages: number
  currentPage: number
  onClick: (page: number) => void
}

export const Pagination = (props: Props) => {
  const { currentPage, totalPages, onClick } = props
  const pages = generatePages(currentPage, totalPages)
  return (
    <Container title="Reviews Pagination" role="form">
      <Prev
        aria-label="Go to Previous Reviews Page"
        disabled={currentPage === 0}
        onClick={() => onClick(currentPage - 1)}
      >
        <PrevSvg role="img" aria-label="Previous Icon" />
      </Prev>
      <Pages>
        {pages.map((page, index) =>
          typeof page === "string" ? (
            <PageEllipsis aria-label="ellipsis" key={`${page}-${index}`}>
              ...
            </PageEllipsis>
          ) : (
            <PageNumber
              aria-label={`Go to page ${page + 1}`}
              key={`${page}-${index}`}
              active={page === currentPage}
              onClick={() => onClick(page)}
            >
              {page + 1}
            </PageNumber>
          )
        )}
      </Pages>
      <Next
        aria-label="Go to Next Reviews Page"
        disabled={currentPage + 1 === totalPages}
        onClick={() => onClick(currentPage + 1)}
      >
        <NextSvg role="img" aria-label="Next Icon" />
      </Next>
    </Container>
  )
}
