import React from "react"
import {
  YotpoProductReviews,
  YotpoProductReviewsImagesData,
} from "typings/graphql"
import {
  ItemGrid,
  InitialContainer,
  InitialCheckmark,
  ReviewMeta,
  ReviewerDateLine,
  Reviewer,
  VerifiedBuyer,
  StarContainer,
  ReviewBody,
  ReviewTitle,
  ReviewContent,
  ReviewThumbContainer,
  ReviewThumb,
} from "./YotpoReviewItem.styles"
import { ReviewStars } from "components/YotpoReviewList/ReviewStars"
import Checkmark from "assets/svg/check.svg"

type Props = {
  review: YotpoProductReviews | null
  reviewCount: number
  backgroundColor: string
  openGallery: (
    newActiveImages: YotpoProductReviewsImagesData[],
    newActiveImgIdx: number
  ) => void
}

export const YotpoReviewItem = ({
  review,
  reviewCount,
  backgroundColor,
  openGallery,
}: Props) => {
  const authorsName = review?.user?.displayName ?? ""
  const authorsInitial = authorsName[0] ?? ""
  const reviewScore = review?.score ?? 0
  const reviewTitle = review?.title ?? ""
  const reviewContent = review?.content ?? ""
  const reviewImages = review?.imagesData
  return (
    <ItemGrid>
      <InitialContainer>
        {authorsInitial.toUpperCase()}
        <InitialCheckmark $borderColor={backgroundColor}>
          <Checkmark role="img" aria-label="Checkmark Icon" />
        </InitialCheckmark>
      </InitialContainer>
      <ReviewMeta>
        <ReviewerDateLine>
          <span>
            <Reviewer dangerouslySetInnerHTML={{ __html: authorsName }} />
            <VerifiedBuyer>Verified</VerifiedBuyer>
          </span>
        </ReviewerDateLine>
        <StarContainer>
          <ReviewStars starCount={reviewScore} reviewCount={reviewCount} />
        </StarContainer>
      </ReviewMeta>
      <ReviewBody>
        <ReviewTitle dangerouslySetInnerHTML={{ __html: reviewTitle }} />
        <ReviewContent dangerouslySetInnerHTML={{ __html: reviewContent }} />
        {reviewImages?.length && (
          <ReviewThumbContainer>
            {reviewImages?.map((reviewImage, index) => (
              <ReviewThumb
                key={reviewImage.id}
                src={reviewImage.thumbUrl}
                alt={`Image ${index} for ${reviewTitle}`}
                onClick={() => openGallery(reviewImages, index)}
              />
            ))}
          </ReviewThumbContainer>
        )}
      </ReviewBody>
    </ItemGrid>
  )
}
