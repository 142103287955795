import React, { useContext } from "react"
import { ProductContext } from "contexts/ProductContext"
import { SanityYotpoReviewList } from "typings/graphql"
import { SingleProductReviewList } from "./SingleProductReviewList"

export const SanityYotpoReviewListBlock = ({
  fields,
}: {
  fields: SanityYotpoReviewList
}) => {
  const { review } = useContext(ProductContext)

  if (!review) {
    return null
  }

  return <SingleProductReviewList fields={fields} review={review} />
}

export default SanityYotpoReviewListBlock
