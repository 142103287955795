import React, { useState, useContext, useEffect } from "react"
import { ThemeContext, ThemeProvider } from "styled-components"
import { Grid } from "components/UI/Grid/Grid"
import { ReviewStars } from "./ReviewStars"
import {
  mapYotpoReviewItems,
  getAverageScore,
  getVisibleReviews,
} from "utils/reviewUtils"
import {
  Container,
  Separator,
  ReviewsNumber,
  PaginationContainer,
  Title,
  ScoreTop,
  TotalScore,
  StarLegend,
  LegendList,
  LegendItem,
  LegendProgress,
  LegendProgressActive,
  LegendCount,
  SearchInputContainer,
  SearchHeader,
  NoResults,
} from "./YotpoReviewList.styles"
import { YotpoProduct, YotpoProductReviewsImagesData } from "typings/graphql"
import { ModeType } from "typings/modules"
import { scrollToElement } from "utils/scrollUtils"
import { Pagination } from "components/UI/Pagination/Pagination"
import { YotpoReviewItem } from "components/YotpoReviewItem/YotpoReviewItem"
import { PortableText } from "components/UI/PortableText/PortableText"
import { ReviewGalleryModal } from "./ReviewGalleryModal"
import { SearchInputPill } from "components/Search/SearchInput/SearchInput"
import { algoliaReviewsIndexName } from "config/searchConfig"
import algoliasearch from "algoliasearch"
import { tracking } from "utils/tracking"
import { useMinReviewsNumber } from "hooks/useMinReviewsNumber"

const reviewsPerPageConst = 5

type Reviews = YotpoProduct[] | null

export const YotpoReviewList = ({
  mode,
  title,
  backgroundColor,
  reviews,
}: {
  mode: ModeType
  title: string
  backgroundColor: string
  reviews: Reviews
}) => {
  const searchInputRef = React.useRef<HTMLInputElement>()
  const themeContext = useContext(ThemeContext)
  const mappedReviews = mapYotpoReviewItems(reviews)
  const [filteredScore, setFilteredScore] = useState(null)
  const [searchHits, setSearchHits] = useState<any[]>(null)
  const [activeReviews, setActiveReviews] = useState(mappedReviews)
  const [currentPage, setCurrentPage] = useState(0)
  const [activeImages, setActiveImages] = useState<
    YotpoProductReviewsImagesData[]
  >([])

  const [activeImgIdx, setActiveImgIdx] = useState(0)
  const hasScoreFilter = filteredScore != null
  const hasSearchResults = searchHits != null
  const totalCount = activeReviews?.length ?? 0
  const totalPages = Math.ceil(totalCount / reviewsPerPageConst)
  const visibleReviews = getVisibleReviews(
    currentPage,
    reviewsPerPageConst,
    activeReviews
  )
  const totalScore = getAverageScore(reviews)

  const openGallery = (
    newActiveImages: YotpoProductReviewsImagesData[],
    newActiveImgIdx: number
  ) => {
    setActiveImages(newActiveImages)
    setActiveImgIdx(newActiveImgIdx)
  }
  const legendItems = [...Array(5)]
  const filteredIdx = legendItems.length + 1 - filteredScore
  const algoliaClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  )
  const reviewsSearchIndex = algoliaClient.initIndex(algoliaReviewsIndexName)

  useEffect(() => {
    if (!hasScoreFilter && !hasSearchResults) {
      setActiveReviews(mappedReviews)
    } else {
      const filtered = mappedReviews.reduce((reviews, review) => {
        if (hasScoreFilter && review.score !== filteredScore) return reviews

        if (hasSearchResults) {
          const searchHit = searchHits.find(
            hit => hit.objectID === review.id.toString()
          )
          if (!searchHit) return reviews

          reviews.push({
            ...review,
            title: searchHit._highlightResult.title.value,
            content: searchHit._highlightResult.content.value,
          })
        } else {
          reviews.push(review)
        }

        return reviews
      }, [])

      setActiveReviews(filtered)
    }
  }, [filteredScore, searchHits])

  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === "Enter") {
      scrollToElement("#yotpoReviews")
      setCurrentPage(0)

      if (event.currentTarget.value?.length) {
        const searchQuery = event.currentTarget.value
        tracking.searched(searchQuery, "reviews")

        reviewsSearchIndex.search(searchQuery).then(({ hits }) => {
          setSearchHits(hits)
        })
      } else {
        setSearchHits(null)
      }
    }
  }

  const searchInputXClicked = () => {
    searchInputRef.current.value = ""

    setSearchHits(null)
  }

  const minReviewsNumber = useMinReviewsNumber()

  return (mappedReviews?.length || 0) >= minReviewsNumber ? (
    <>
      <ThemeProvider theme={{ localTheme: themeContext[mode] }}>
        <Container id="yotpoReviews" $bgColor={backgroundColor}>
          <Grid base="1 [10] 1" md="1 [14] 1" lg="6 [12] 6">
            {title && (
              <Title>
                <PortableText blocks={title} $mode={mode} />
              </Title>
            )}
            <Grid base="[1]" md="[6] [10]" lg="[8] [16]" xxl="[3] [6] 3">
              <div>
                <ScoreTop>
                  <TotalScore $mode={mode}>{totalScore.toFixed(1)}</TotalScore>
                  <ReviewStars
                    starCount={totalScore}
                    reviewCount={mappedReviews.length}
                  />
                </ScoreTop>
                <ReviewsNumber>
                  Based on {mappedReviews.length} Reviews
                </ReviewsNumber>
              </div>
              <StarLegend $filtered={filteredIdx}>
                <LegendList>
                  {legendItems.map((_, i) => {
                    const score = legendItems.length - i
                    const reviewCount = mappedReviews.filter(
                      review => review.score === score
                    ).length
                    const width = (reviewCount / mappedReviews.length) * 100
                    const enabled = reviewCount > 0
                    const onClick = () => {
                      if (enabled) {
                        scrollToElement("#yotpoReviews")
                        setCurrentPage(0)

                        if (filteredScore === score) {
                          setFilteredScore(null)
                        } else {
                          setFilteredScore(score)
                        }
                      }
                    }
                    return (
                      <LegendItem
                        key={`legend-item-${score}`}
                        $active={filteredScore}
                        $selected={filteredScore === score}
                        disabled={!enabled}
                        onClick={onClick}
                      >
                        <ReviewStars
                          starCount={score}
                          reviewCount={reviewCount}
                        />
                        <LegendProgress>
                          <LegendProgressActive $width={width} />
                        </LegendProgress>
                        <LegendCount>({reviewCount})</LegendCount>
                      </LegendItem>
                    )
                  })}
                </LegendList>
              </StarLegend>
            </Grid>
            <Separator />
            <SearchInputContainer>
              <SearchHeader>Filter Reviews</SearchHeader>
              <SearchInputPill
                ref={searchInputRef}
                onKeyDown={handleSearch}
                onXClicked={searchInputXClicked}
                placeholder="Search"
              />
            </SearchInputContainer>
            <Separator />
            {visibleReviews?.length > 0 ? (
              visibleReviews.map((r, i) => (
                <div key={`yotpo-review-${i}`}>
                  <YotpoReviewItem
                    review={r}
                    backgroundColor={backgroundColor}
                    openGallery={openGallery}
                    reviewCount={visibleReviews.length}
                  />
                  <Separator />
                </div>
              ))
            ) : (
              <NoResults>0 results found</NoResults>
            )}
            {totalPages > 0 && (
              <PaginationContainer>
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onClick={(page: number) => {
                    scrollToElement("#yotpoReviews")
                    setCurrentPage(page)
                  }}
                />
              </PaginationContainer>
            )}
          </Grid>
        </Container>
      </ThemeProvider>
      {!!activeImages?.length && (
        <ReviewGalleryModal
          activeImages={activeImages}
          index={activeImgIdx}
          setActiveImages={setActiveImages}
        />
      )}
    </>
  ) : null
}
