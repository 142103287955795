import styled, { css } from "styled-components"
import { mq, typography } from "styles"
import { Disabled } from "typings/modules"
import { NoStyleButton } from "components/UI/Button/NoStyleButton"

export const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  grid-gap: 16px;
`

export const enabledStyles = css`
  &:hover {
    svg {
      fill: var(--pagination-hover-icon);
    }
  }
`

export const disabledStyles = css`
  cursor: not-allowed;
  svg {
    fill: var(--pagination-inactive-icon);
    opacity: 40%;
  }
`

export const sharedPagingStyles = (disabled: boolean) => css`
  ${typography.caption};
  color: var(--pagination-active-icon);
  svg {
    transition: fill 0.3s ease;
  }
  position: relative;
  top: 3px;
  ${disabled && disabledStyles};
  ${!disabled && enabledStyles};
`

export const Prev = styled(NoStyleButton)<Disabled>`
  ${({ disabled }) => sharedPagingStyles(disabled)};
`
export const Next = styled(NoStyleButton)<Disabled>`
  ${({ disabled }) => sharedPagingStyles(disabled)};
`
export const Pages = styled.div``

export const activeStyles = css`
  background-color: var(--pagination-active-fill);
  color: var(--pagination-active-text);
`

export const inactiveStyles = css`
  color: var(--pagination-inactive-text);
  ${mq.minWidth("lg")} {
    &:hover {
      color: var(--pagination-hover-text);
      background: var(--pagination-hover-fill);
    }
  }
`

export const PageNumber = styled(NoStyleButton)<{ active: boolean }>`
  ${typography.h6};
  margin: 0 4px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${({ active }) => (active ? activeStyles : inactiveStyles)};
  transition: color 0.3s ease, background 0.3s ease;
`

export const PageEllipsis = styled.span`
  ${typography.h6};
  margin: 0 12px;
  width: 32px;
  height: 32px;
  color: var(--pagination-inactive-text);
`
