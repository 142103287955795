import {
  Maybe,
  YotpoProductReviews,
  YotpoProductBottomline,
  SanityProduct,
} from "typings/graphql"
import { YotpoProduct } from "typings/graphql"
import { decodeProductId } from "./decodeId"

type MappedReviewItem = YotpoProductReviews & {
  bottomLine: YotpoProductBottomline
  productId: string
}

const flatten = (arr: any[]) => [].concat(...arr)

const range = (start: number, end: number) =>
  Array.from({ length: end - start }, (v, i) => i + start)

export const mapYotpoReviewItems = (products: Maybe<YotpoProduct[]>) => {
  if (!products || !products.length) return []
  const mapped = products?.map(product =>
    product?.reviews?.map(review => ({
      productId: product?.productId,
      bottomLine: product?.bottomline,
      ...review,
    }))
  )
  const flattened: MappedReviewItem[] = flatten(mapped || [])
  const ordered = flattened.sort(
    (a, b) => Date.parse(b?.createdAt) - Date.parse(a?.createdAt)
  )
  return ordered
}

export const getAverageScore = (products: Maybe<YotpoProduct[]>) => {
  const productsWithReviews = products?.filter(
    product => (product?.reviews?.length || 0) > 0
  )
  const count = productsWithReviews?.length || 1
  const score =
    productsWithReviews?.reduce(
      (acc, item) => (acc += item.bottomline?.averageScore || 0),
      0
    ) || 1
  return score / count
}

export const getVisibleReviews = (
  currentPage: number,
  reviewsPerPage: number,
  reviews?: (YotpoProductReviews | null)[]
) => {
  return (
    reviews?.slice(
      currentPage * reviewsPerPage,
      (currentPage + 1) * reviewsPerPage
    ) || []
  )
}

export const generatePages = (currIdx: number, numberOfPages: number) => {
  if (numberOfPages <= 5) {
    return range(0, numberOfPages)
  }
  const lastIdx = numberOfPages - 1
  if (currIdx === 0 || currIdx === 1) {
    return [0, 1, 2, "...", lastIdx]
  }
  if (currIdx === 2) {
    return [0, 1, 2, 3, "...", lastIdx]
  }
  if (currIdx === lastIdx || currIdx === lastIdx - 1) {
    return [0, "...", lastIdx - 2, lastIdx - 1, lastIdx]
  }
  if (currIdx === lastIdx - 2) {
    return [0, "...", lastIdx - 3, lastIdx - 2, lastIdx - 1, lastIdx]
  }
  return [0, "...", currIdx - 1, currIdx, currIdx + 1, "...", lastIdx]
}

export const mergeReviews = (yotpoProducts: YotpoProduct[]) => {
  const nonEmptyProducts = yotpoProducts.filter(p => p.bottomline?.totalReview)
  const productId = nonEmptyProducts[0]?.productId
  const count = nonEmptyProducts?.length
  const newReview =
    count > 1
      ? nonEmptyProducts.reduce((a, b) => {
          const scoreA = a?.bottomline?.averageScore ?? 0
          const scoreB = b?.bottomline?.averageScore ?? 0
          const totalA = a?.bottomline?.totalReview ?? 0
          const totalB = b?.bottomline?.totalReview ?? 0
          const averageScore = (scoreA + scoreB) / 2
          const totalReview = totalA + totalB
          const reviews = [...(a?.reviews || []), ...(b?.reviews || [])]
          return {
            ...b,
            ...{
              bottomline: {
                averageScore,
                totalReview,
              },
              reviews,
              productId,
            },
          }
        })
      : nonEmptyProducts[0]
  return newReview
}

export const getYotpoIdsFromProduct = (sanityProduct: SanityProduct) => {
  const aggregateReviewIds =
    sanityProduct?.aggregateReviewProducts?.map(
      reviewProduct => reviewProduct.shopifyId
    ) || []

  const shopifyId = sanityProduct.shopifyProduct.shopifyId
  const reviewIds = [shopifyId, ...aggregateReviewIds]

  return reviewIds.filter(Boolean).map(decodeProductId)
}
