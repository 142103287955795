import { NoStyleButton } from "components/UI/Button/NoStyleButton"
import styled from "styled-components"
import { mq, typography } from "styles"
import { $Active, $BgColor, $Mode, $Selected, Disabled } from "typings/modules"

export const Container = styled.div<$BgColor>`
  background: ${({ $bgColor }) => $bgColor};
  padding: 64px 0;
  ${mq.minWidth("md")} {
    padding: 96px 0;
  }

  em {
    background-color: var(--reviews-highlighter-fill);
  }
`

export const Separator = styled.div`
  border-bottom: 1px solid
    ${({ theme }) => theme.localTheme.ui?.divider.opacityHex};
`

export const Title = styled.div`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.localTheme.text?.primary.opacityHex};
  > *:last-child {
    margin: 0;
  }
  padding-bottom: 32px;
  ${mq.minWidth("md")} {
    padding-bottom: 48px;
  }
  ${mq.minWidth("lg")} {
    padding-bottom: 64px;
  }
`

export const ReviewsNumber = styled.span`
  color: ${({ theme }) => theme.localTheme.text?.primary.opacityHex};
  ${typography.bodySmall};
  text-align: center;
  display: block;
  ${mq.minWidth("md")} {
    text-align: left;
  }
`

export const PaginationContainer = styled.div`
  margin-top: 32px;
`

export const ScoreTop = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 9px;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
  ${mq.minWidth("md")} {
    justify-content: flex-start;
  }
`

export const TotalScore = styled.h2<$Mode>`
  color: ${({ $mode }) => `var(--text-headline-${$mode})`};
  ${typography.h3};
`

export const LegendItem = styled(NoStyleButton)<$Active & $Selected & Disabled>`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr 60px;
  grid-gap: 16px;
  width: 100%;
  align-items: center;
  transition: opacity 0.2s ease;
  opacity: ${({ $active, $selected }) =>
    $active ? ($selected ? 1 : 0.32) : ""};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  &:not(:last-child) {
    margin-bottom: 4px;
  }
`

export const StarLegend = styled.div<{ $filtered: number }>`
  margin: 24px 0;
  ${mq.minWidth("md")} {
    margin: 0 0 24px;
    border-left: 1px solid
      ${({ theme }) => theme.localTheme.ui?.divider.opacityHex};
  }
  ${mq.minWidth("lg")} {
    &:hover {
      ${LegendItem} {
        opacity: 1;
        transition: opacity 0.2s ease;
        ${({ $filtered }) =>
          $filtered
            ? `
          &:not(:hover):not(:nth-child(${$filtered})) {
            opacity: 0.32;
          }
        `
            : `
          &:not(:hover) {
            opacity: 0.32;
          }
        `}
      }
    }
  }
`

export const LegendList = styled.div`
  ${mq.minWidth("md")} {
    padding-left: 16px;
  }
`

export const LegendProgress = styled.div`
  background: var(--slider-bar-inactive);
  height: 16px;
  width: 100%;
  position: relative;
`

export const LegendProgressActive = styled.div<{ $width: number }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${({ $width }) => `${$width}%`};
  background: var(--slider-bar-active);
`

export const LegendCount = styled.span`
  ${typography.caption};
  color: ${({ theme }) => theme.localTheme.text?.primary.opacityHex};
  text-align: left;
`

export const SearchInputContainer = styled.div`
  margin: 24px 0;

  ${mq.maxWidth("md")} {
    margin-top: 16px;
  }
`

export const SearchHeader = styled.div`
  ${typography.bodySmall};
  font-weight: 700;
  color: ${({ theme }) => theme.localTheme.text?.primary.opacityHex};
  margin-bottom: 16px;

  ${mq.maxWidth("md")} {
    margin-top: 12px;
  }
`

export const NoResults = styled.div`
  ${typography.body};
  color: ${({ theme }) => theme.localTheme.text?.primary.opacityHex};
  padding: 24px 0;
`
