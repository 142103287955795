import React from "react"
import { Maybe, SanityYotpoReviewList, YotpoProduct } from "typings/graphql"
import { ModeType } from "typings/modules"
import { YotpoReviewList } from "./YotpoReviewList"

export const SingleProductReviewList = ({
  fields,
  review,
}: {
  fields: SanityYotpoReviewList
  review: Maybe<YotpoProduct>
}) => {
  return (
    <YotpoReviewList
      mode={fields.mode as ModeType}
      title={fields._rawTitle}
      backgroundColor={fields.backgroundColor.opacityHex}
      reviews={[review]}
    />
  )
}
