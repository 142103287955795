import styled from "styled-components"
import { cursors, mq } from "styles"
import Slider from "react-slick"

export const ReviewImage = styled.img`
  width: 100%;
  height: 100%;
  max-height: 100vh;
  max-width: 100vw;
  ${mq.minWidth("md")} {
    max-width: 80vw;
    max-height: 80vh;
  }
`

export const ReviewImageSlider = styled(Slider)`
  width: 100vw;
  height: auto;

  .slick-dots {
    cursor: pointer;
    bottom: 32px;
  }

  .slick-dots li {
    width: auto;
    padding-left: 3px;
    padding-right: 3px;
    margin-left: 3px;
    margin-right: 3px;
  }

  .slick-dots li:before {
    display: none;
  }

  .slick-dots li button {
    width: auto;
    cursor: pointer;
  }

  .slick-dots li button:before {
    content: "";
    cursor: pointer;
    font-size: 0px;
    line-height: normal;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    width: 8px;
    height: 8px;
    background: ${({ theme }) => theme.light.ui?.icon?.opacityHex};
    border-radius: 50%;
    opacity: 1;
  }

  .slick-dots li.slick-active button:before {
    opacity: 1;
    background-color: transparent;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.light.ui?.icon?.opacityHex};
    bottom: 32px;
    ${mq.maxWidth("md")} {
      bottom: 18px;
    }
  }

  .slick-track {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .slick-slide > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ${mq.minWidth("lg")} {
    width: 100vw;
    height: 80vh;
    .slick-slide > div > img {
      width: auto !important;
    }
    .slick-arrow {
      &:before {
        content: "";
      }
      padding: 0;
      transform: translate(0);
      position: absolute;
      top: 0;
      width: 50%;
      height: 100%;
      z-index: 1;
    }
    .slick-prev {
      ${mq.minWidth("lg")} {
        left: 0;
        &:hover {
          ${cursors.slickPrevWhiteCursor};
        }
      }
    }
    .slick-next {
      right: 0;
      ${mq.minWidth("lg")} {
        &:hover {
          ${cursors.slickNextWhiteCursor};
        }
      }
    }
  }
`
